import axios from "axios";

export default {
  getBerichte() {
    return axios.get(process.env.VUE_APP_AIS_BASE_URL_OLD + "/berichte/");
  },
  updateBerichte(berichte) {
    return axios
      .post(process.env.VUE_APP_AIS_BASE_URL_OLD + "/berichte/", berichte)
      .catch(error => {
        throw error.response.data.errorMessage;
      });
  }
};
