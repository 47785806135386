<template>
  <v-app>
    <v-container>
      <top-bar :navpos="nav" />
      <v-main>
        <v-toolbar flat>
          <help />
          <v-toolbar-title>Berichteübersicht</v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
          <div class="title">
            <v-data-table
              :headers="headers"
              :items="berichte"
              sort-by="bezeichnung"
              class="elevation-1"
              dense
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-toolbar-title>Berichte</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-spacer></v-spacer>
                  <v-dialog v-model="dialog" max-width="800px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        class="mb-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        Hinzufügen
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">{{ formTitle }}</span>
                      </v-card-title>

                      <v-card-text>
                        <p
                          v-html="formDescription"
                          class="subtitle-2 text-left"
                        ></p>
                        <v-divider class="mx-1"></v-divider>
                        <v-container>
                          <v-form ref="form_bericht" v-model="valid">
                            <v-row>
                              <v-col cols="12" sm="12" md="12">
                                <v-text-field
                                  v-model="editedItem.bezeichnung"
                                  label="Bezeichnung"
                                  :rules="rules.bezeichnung"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="12" sm="12" md="12">
                                <v-textarea
                                  v-model="editedItem.beschreibung"
                                  label="Beschreibung"
                                  :rules="rules.beschreibung"
                                  hint="Beschreiben Sie hier den Inhalt des Berichtes."
                                ></v-textarea>
                              </v-col>
                              <v-col cols="12" sm="12" md="12">
                                <v-textarea
                                  v-model="editedItem.statement"
                                  label="Anweisung"
                                  :rules="rules.statement"
                                  hint="Spezifizieren Sie hier die SQL-Anweisung des Berichtes."
                                ></v-textarea>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-container>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="close">
                          Abbruch
                        </v-btn>
                        <v-btn color="primary" text @click="save">
                          Übernehmen
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h6"
                        >Möchten Sie den Bericht wirklich löschen?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="closeDelete"
                          >Abbruch</v-btn
                        >
                        <v-btn color="primary" text @click="deleteItemConfirm"
                          >OK</v-btn
                        >
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-toolbar>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      @click="downloadItem(item)"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-download
                    </v-icon>
                  </template>
                  <span> {{ item.bezeichnung }} herunterladen</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span> {{ item.bezeichnung }} bearbeiten</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      small
                      @click="deleteItem(item)"
                      color="error"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span> {{ item.bezeichnung }} löschen</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </div>
        </v-container>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-btn
                class="mr-1"
                @click="saveBerichte"
                outlined
                v-if="berichte.length > 0 || form_saved == false"
              >
                speichern
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-btn
                @click="initialize"
                outlined
                v-if="berichte.length > 0 || form_saved == false"
              >
                Zurücksetzen
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
        <v-snackbar v-model="snackbar" :timeout="snackbar_timeout">
          {{ message }}
          <template v-slot:action="{ attrs }">
            <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
              Schliessen
            </v-btn>
          </template>
        </v-snackbar>
      </v-main>
      <footer-bar/>
    </v-container>
  </v-app>
</template>

<script>
import axios from "axios";
import TopBar from "../../../components/TopBar.vue";
import BerichteService from "@/services/old/BerichteService.js";
import Help from "@/components/old/help/BerichteHelp.vue";
import FooterBar from "../../../components/Footer.vue";

const tableHeaders = [
  { text: "Bericht", value: "bezeichnung" },
  { text: "Beschreibung", value: "beschreibung" },
  { text: "Aktionen", value: "actions", sortable: false }
];

const nav_items = [
  {
    text: "Main",
    disabled: false,
    to: { path: "/main" }
  },
  {
    text: "Main(old)",
    disabled: false,
    to: { path: "main" }
  },
  {
    text: "Berichtübersicht",
    disabled: true,
    to: { path: "berichtuebersicht" }
  }
];

export default {
  components: { TopBar, Help, FooterBar },
  name: "Berichtübersicht",
  props: {},
  data() {
    return {
      valid: true,
      dialog: false,
      dialogDelete: false,
      headers: tableHeaders,
      nav: nav_items,
      berichte: [],
      rules: {
        bezeichnung: [v => !!v || "Bezeichnung erforderlich"],
        beschreibung: [v => !!v || "Beschreibung erforderlich"],
        statement: [v => !!v || "SQL-Anweisung erforderlich"]
      },
      editedIndex: -1,
      editedItem: {
        bezeichnung: "",
        beschreibung: "",
        statement: ""
      },
      defaultItem: {
        bezeichnung: "",
        beschreibung: "",
        statement: ""
      },
      snackbar: false,
      message: "",
      snackbar_timeout: 2000,
      form_saved: true
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Bericht Hinzufügen" : "Bericht Ändern";
    },
    formDescription() {
      return "Spezifizieren Sie den Bericht.";
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    }
  },
  methods: {
    initialize() {
      this.loadBerichte();
    },
    async loadBerichte() {
      let resp = await BerichteService.getBerichte();
      this.berichte = resp.data;
      if (resp.status == 200) {
        this.message = "Berichte gelesen.";
      } else {
        this.message = resp.data;
      }
    },
    editItem(item) {
      this.editedIndex = this.berichte.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedIndex = this.berichte.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "data.txt");
      document.body.appendChild(link);
      link.click();
    },
    async downloadItem(item) {
      this.editedIndex = this.berichte.indexOf(item);

      axios({
        method: "get",
        url:
          `${process.env.VUE_APP_AIS_BASE_URL_OLD}/download/` +
          item.id.substring(1),
        responseType: "arraybuffer"
      })
        .then(response => {
          this.forceFileDownload(response);
        })
        .catch(() => console.log("error occured"));
    },
    deleteItemConfirm() {
      this.berichte.splice(this.editedIndex, 1);
      this.closeDelete();
      this.form_saved = false;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      this.validate();
      if (this.valid) {
        if (this.editedIndex > -1) {
          Object.assign(this.berichte[this.editedIndex], this.editedItem);
        } else {
          this.berichte.push(this.editedItem);
        }
        this.close();
        this.form_saved = false;
      }
    },
    async saveBerichte() {
      try {
        let resp = await BerichteService.updateBerichte(this.berichte);
        this.berichte = resp.data;
        if (resp.status == 200) {
          this.message = "Änderungen wurden gespeichert.";
          this.form_saved = true;
        } else {
          this.message = resp.errorMessage;
        }
      } catch (err) {
        this.message = err;
        this.initialize();
      }
      this.snackbar = true;
    },
    validate() {
      this.$refs.form_bericht.validate();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (!this.form_saved) {
      const answer = window.confirm(
        "Änderungen sind noch nicht gespeichert worden.\nSollen die Änderungen verworfen werden?"
      );
      if (answer) {
        this.form_saved = true;
        next();
      } else {
        next(false);
      }
    } else {
      next();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.title {
  background-color: #ffffff;
  margin-top: 5px;
  align-self: center;
}
</style>
